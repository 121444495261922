import "./Feature.scss";

import { useEffect } from "react";
import featureIcon1 from './../assets/feature-1.png';
import featureIcon2 from './../assets/feature-2.png';
import featureIcon3 from './../assets/feature-3.png';
import featureIcon4 from './../assets/feature-4.png';
import Swiper, { Pagination, EffectFade } from 'swiper';
import 'swiper/css';
import 'swiper/css/effect-fade';


export default function Feature() {
  // const [mySwiper, setMySwiper] = useState(null);

  useEffect(() => {
    let s = new Swiper('.feature-swiper', {
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      },
      
      speed: 400,
      spaceBetween: 100,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
        renderBullet: (index, className) => {
          return "<span class='atag "+className+"'>"+(index+1)+"</span>"
        },
        bulletActiveClass: "active",
      },
      modules: [Pagination, EffectFade],
    });
    // setMySwiper(s);
    return () => s.destroy();
  }, []);

  return (
    <section className="common-page feature-page" id="stats">
      <div className='content'>
        <div className='left'>
          <h2>Features</h2>
          <div className="textfont1">
          Sirius Finance is the first stablecoin AMM<br/>
          infrastructure on Astar Network, easy for<br/>
          everyone to begin their journey on Polkadot.
          </div>
          <div><a target="_blank" rel="noreferrer" href="https://docs.sirius.finance/" className='primary button'>View Docs</a></div>
        </div>
        <div className='right textfont1'>
          <div className='bot swiper-pagination' id="feature-pagination">
            {/* <span className='atag active'>1</span>
            <span className='atag'>2</span>
            <span className='atag'>3</span>
            <span className='atag'>4</span> */}
          </div>
          <div className="top feature-swiper">
            <div className="feature-slider-wrapper swiper-wrapper">
              <div className="feature-slider-item swiper-slide">
                <div>
                  Swap stablecoins with low-slippage, minimum impermanent loss & negligible transaction fees
                </div>
                <img src={featureIcon1} alt="feature icon"/>
              </div>
              <div className="feature-slider-item swiper-slide">
                <div>
                  Connect Astar and Polkadot native protocols with growing stablecoins adoption to address volatility
                </div>
                <img src={featureIcon2} alt="feature icon"/>
              </div>
              <div className="feature-slider-item swiper-slide">
                <div>
                  Cross between EVM-compatible chains and Polkadot ecosystem conveniently
                </div>
                <img src={featureIcon3} alt="feature icon"/>
              </div>
              <div className="feature-slider-item swiper-slide">
                <div>
                  More yield farming and arbitrage opportunities for LPers & traders
                </div>
                <img src={featureIcon4} alt="feature icon"/>
              </div>
              
            </div>
            
            
          </div>
        </div>
      </div>
    </section>
  )
}
import "./BgElements.scss";

export function Ball(props) {
  const rate = 0.06975;
  const w = props.size * rate;
  const l = props.x * rate;
  const t = props.y / 800 * 100;

  return (
    <div className={props.blur?"blur ball":"ball"} style={{
      width: w+"vw", 
      height: w+"vw",
      left: l+"vw",
      top: t+"vh"
    }}></div>
  )
}


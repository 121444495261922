import "./Governance.scss"
import React from 'react'
import Gov3BG from './../assets/gov-3-bg2.png'
import Gov3BGMB from './../assets/mobile/gov-2.png'
import { useMediaQuery } from 'react-responsive'
import Gov_1 from './../assets/mobile/gov-1.png'

export default function Governance() {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' })
  return (
    <>
      <section className="common-page governance-page" id="governance">
        <div className='content'>
          <div className='left'>
            <h2>Governance</h2>
            {isTabletOrMobile && (
            <img src={Gov_1} alt="gov-1" />
            )}
            <div className='textfont1'>The design of veSRS tokenomic aims at 3 goals:</div>
            <div className='textfont2'>
              <ul>
                <li>Encouraging participation by staking SRS to eventually empower the transformation from Sirius Finance into Sirius DAO</li>
                <li>Attracting and keeping liquidity by generating more yield farming and arbitrage opportunities</li>
                <li>Encouraging SRS buy demands and decreasing sell pressures</li>
              </ul>
            </div>
          </div>
          <div className='right'></div>
        </div>
      </section>
      
      <section className="common-page governance-page2" id="governance2">
        <div className='content'>
          <div className='textfont2'>Governance</div>
          <h4>Workflow</h4>
          <div className='content-img'></div>
        </div>
      </section>

      <section className="common-page governance-page3" id="governance3">
        <div className='content'>
          <div className='textfont2' style={{visibility: "hidden"}}>Governance</div>
          <h4>SRS Token Utility</h4>
          <div className='content-text'>
            {isTabletOrMobile?(
              <img src={Gov3BGMB} alt="Gov3BGMB" />
            ):(
              <img src={Gov3BG} alt="gov3bg"/>
            )}
            
          </div>
        </div>
        
      </section>
    </>
  )
}
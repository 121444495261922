
export function toPX(value) {
  return parseFloat(value) / 100 * (/vh/gi.test(value) ? window.innerHeight : window.innerWidth);
}

export function commify(str) {
  const parts = str.split(".")
  if (parts.length > 2) throw new Error("commify string cannot have > 1 period")
  // if (parts.length === 1) return str;
  const [partA, partB] = parts
  const hasPercent = partB !== undefined && partB.indexOf("%") > -1 ? "%" : ""
  if (partA.length === 0)
    return partB === undefined
      ? ""
      : `.${partB.replace("%", "").slice(0, 2)}${hasPercent}`
  const mod = partA.length % 3
  const div = Math.floor(partA.length / 3)
  // define a fixed length array given the expected # of commas added
  const commaAArr = new Array(partA.length + (mod === 0 ? div - 1 : div))
  // init pointers for original string and for commified array
  let commaAIdx = commaAArr.length - 1
  // iterate original string backwards from the decimals since that's how commas are added
  for (let i = partA.length - 1; i >= 0; i--) {
    // revIdx is the distance from the decimal place eg "3210."
    const revIdx = partA.length - 1 - i
    // add the character to the array
    commaAArr[commaAIdx--] = partA[i]
    // add a comma if we are a multiple of 3 from the decimal
    if ((revIdx + 1) % 3 === 0) {
      commaAArr[commaAIdx--] = ","
    }
  }
  const commifiedA = commaAArr.join("")
  return commifiedA;
  // return partB === undefined
  //   ? commifiedA
  //   : `${commifiedA}.${partB.replace("%", "").slice(0, 2)}${hasPercent}`
}

export function TryRedo(fn) {
  let count = 0;
  const maxTries = 3;
  while(true) {
      try {
          fn.call();
          break ;
      } catch (e) {
        if (++count >= maxTries) {
          console.log(e);
          break ;
        }
        fn.call();
      }
  }
}
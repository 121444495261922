import React, { useEffect, useState } from 'react';
import TopMenu from './components/TopMenu';
// import { Ball } from './components/BgElements';
import CountDown from './components/CountDown';
import Feature from './components/Feature';
import Governance from './components/Governance';
import Roadmap from './components/Roadmap';
import RoadmapMb from './components/RoadmapMb';
import Ecosystem from './components/Ecosystem';
import Investors from './components/Investors';
import Audit from './components/Audit';
import Join from './components/Join';
import Bg from './components/Bg';

import './App.scss';

import gsap from 'gsap';
import ScrollToPlugin from "gsap/ScrollToPlugin";
import ScrollTrigger from "gsap/ScrollTrigger";
import { toPX } from "./components/Utils";
import { useMediaQuery } from 'react-responsive'

import Swiper, { Mousewheel } from 'swiper';
import 'swiper/css';

function App() {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' })

  const $designRate = 0.06975;
  const $totalDuration = 9;
  gsap.registerPlugin(ScrollToPlugin, ScrollTrigger);
  const [tl, setTl] = useState(null);

  const [s, setS] = useState(null);

  useEffect(() => {
    if(!isTabletOrMobile)
      return ;

    let s = new Swiper('#root', {
      direction: "vertical",
      wrapperClass: "App",
      mousewheel: true,
      slideClass: "common-page",
      slidesPerView: "auto",
      modules: [ Mousewheel ]
    });
    setS(s);
    return () => {
      s.destroy();
      setS(null);
    };  
  }, [isTabletOrMobile])

  useEffect(() =>{
    window.onresize = function(){ window.location.reload(); }
    if(isTabletOrMobile)
      return ;
    
    let tl = gsap.timeline({
      // yes, we can add it to an entire timeline!
      scrollTrigger: {
        trigger: ".App",
        pin: true,   // pin the trigger element while active
        // start: "top top", // when the top of the trigger hits the top of the viewport
        end: toPX("900vh"), // end after scrolling 500px beyond the start
        scrub: 1, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
        // scroller: "#root",
        // snap: "labelsDirectional"
        // snap: {
        //   // snapTo: "labelsDirectional", // snap to the closest label in the timeline
        //   duration: 0.5, // the snap animation should be at least 0.2 seconds, but no more than 3 seconds (determined by velocity)
        //   delay: 0.5, // wait 0.2 seconds from the last scroll event before doing the snapping
        //   ease: "power1.inOut" // the ease of the snap animation ("power3" by default)
        // }
      }
    });

    gsap.set("#countdown-page", {css: {opacity: 1,zIndex:100}});
    tl
    .progress(0).clear(true) 
    .addLabel("start")
      //feature page begin 2 2
      // .to("#stats", {duration: 0.5/$totalDuration})
      .to(".circle", {
        y: toPX('20vh'),
        autoAlpha: 1,
        duration: 1/$totalDuration
      })
      .to("#countdown-page", {
        autoAlpha: 0,
        duration: 1/$totalDuration
      },"-="+1/$totalDuration)
      .to("#stats", {
        autoAlpha: 1,
        duration: 1/$totalDuration
      },"-="+1/$totalDuration)
      .set("#stats", {css:{zIndex:100}}, "-="+0.5/$totalDuration)
      .set("#countdown-page", {css:{zIndex: 0}}, "-="+0.5/$totalDuration)
      
      // .to("#stats", {duration: 0.5/$totalDuration})
      .addLabel("feature")
      //feature page end

      //gov page1 begin 2 4
      // .to("#stats", {duration: 0.5/$totalDuration})
      .to(".circle", {
        scale: 0.5,
        x: toPX(435*$designRate+"vw"),
        y: toPX("18vh") - toPX(280*$designRate+"vw"),
        duration: 1/$totalDuration
      })
      .to(".gov-circle", {
        autoAlpha: 1,
        duration: 1/$totalDuration
      },"-="+1/$totalDuration)
      .to(".gov-title", {
        autoAlpha: 1,
        duration: 1/$totalDuration
      },"-="+1/$totalDuration)
      .to("#stats", {
        autoAlpha: 0,
        duration: 1/$totalDuration
      },"-="+1/$totalDuration)
      .to("#governance", {
        autoAlpha: 1,
        duration: 1/$totalDuration
      },"-="+1/$totalDuration)
      .set("#stats", {css:{zIndex: 0}})
      .set("#governance", {css:{zIndex: 100}})
      // .to("#stats", {duration: 0.5/$totalDuration})
      .addLabel("governance")
      //gov page1 end

      //gov page2 start 2 6
      // .to("#stats", {duration: 0.5/$totalDuration})
      .to("#governance", {
        autoAlpha: 0,
        duration: 1/$totalDuration
      })
      .to(".circle", {
        autoAlpha: 0.1,
        duration: 1/$totalDuration
      },"-="+1/$totalDuration)
      .to(".stage1", {
        autoAlpha: 0,
        duration: 1/$totalDuration
      },"-="+1/$totalDuration)
      .to(".gov-title", {
        y: toPX("16vh"),
        x: toPX(33*$designRate+"vw"),
        // fontSize: 14,
        scale: 0.25,
        duration: 1/$totalDuration
      },"-="+1/$totalDuration)
      .to("#governance2", {
        y: 0,
        autoAlpha: 1,
        duration: 1/$totalDuration
      },"-="+1/$totalDuration)
      .set("#governance", {css:{zIndex: 0}})
      .set("#governance2", {css:{zIndex: 100}})
      // .to("#stats", {duration: 0.5/$totalDuration})
      .addLabel("governance2")
      //gov page2 end

      // gov page3 begin 2 8
      // .to("#stats", {duration: 0.5/$totalDuration})
      .to("#governance2", {//第一个要删掉“-=”+1/14
        autoAlpha: 0,
        duration: 1/$totalDuration
      })
      .to("#governance3", {
        y: 0,
        autoAlpha: 1,
        duration: 1/$totalDuration
      },"-="+1/$totalDuration)
      .set("#governance2", {css:{zIndex: 0}})
      .set("#governance3", {css:{zIndex: 100}})
      // .to("#stats", {duration: 0.5/$totalDuration})
      .addLabel("governance3")
      // gov page3 end

      // ecosystem begin 3 11
      // .to("#stats", {duration: 0.5/$totalDuration})
      .to(".stage2", {
        autoAlpha: 0,
        duration: 1/$totalDuration
      })
      .to(".gov-title", {
        autoAlpha: 0,
        duration: 1/$totalDuration
      },"-="+1/$totalDuration)
      .to(".circle", {
        autoAlpha: 1,
        scale: 0.4,
        // width: toPX(415*$designRate+"vw"),
        // height: toPX(415*$designRate+"vw"),
        x: toPX(-(1120*0.4/2)*$designRate+"vw")+toPX("50vw")-toPX(1120*0.6/2*$designRate+"vw"),//toPX(513*$designRate+"vw"),
        y: toPX(-(1120*0.4/2)*$designRate+"vw")+toPX("50vh")-toPX(1120*0.6/2*$designRate+"vw"),//toPX("50vh")-toPX(415*$designRate/2+"vw"),
        duration: 1/$totalDuration
      },"-="+1/$totalDuration)
      .to("#governance3", {
        autoAlpha: 0,
        duration: 1/$totalDuration
      },"-="+1/$totalDuration)
      .to("#ecosystem", {
        autoAlpha: 1,
        duration: 1/$totalDuration
      },"-="+1/$totalDuration)
      .to(".eco-bg-control", {
        // scale: 1,
        autoAlpha: 1,
        // height: toPX("100vw"),
        duration: 1/$totalDuration
      },"-="+1/$totalDuration)
      
      .set("#governance3", {css:{zIndex: 0}})
      .set("#ecosystem", {css:{zIndex: 100}})
      // .to("#stats", {duration: 0.5/$totalDuration})
      .addLabel("ecosystem")
      // ecosystem end

      //Investors
      .to("#ecosystem", {
        autoAlpha: 0,
        duration: 1/$totalDuration
      })
      .to("#investors", {
        autoAlpha: 1,
        duration: 1/$totalDuration
      },"-="+1/$totalDuration)
      .to(".circle", {
        autoAlpha: 0,
        duration: 1/$totalDuration
      },"-="+1/$totalDuration)
      
      .to(".eco-balls", {
        autoAlpha: 0,
        duration: 1/$totalDuration
      },"-="+1/$totalDuration)
      .to(".eco-bg", {
        scale: 0.5,
        autoAlpha: 0,
        duration: 1/$totalDuration
      },"-="+1/$totalDuration)
      .to(".roadmap-balls", {
        top: 0,
        duration: 1/$totalDuration
      },"-="+1/$totalDuration)

      .set("#ecosystem", {css:{zIndex: 0}})
      .set("#investors", {css:{zIndex: 100}})
      .addLabel("investors")

      //Audit begin
      
      .to("#investors", {
        autoAlpha: 0,
        duration: 1/$totalDuration
      })
      .to("#audit", {
        autoAlpha: 1,
        duration: 1/$totalDuration
      },"-="+1/$totalDuration)
      .set("#investors", {css:{zIndex: 0}}, "-="+0.5/$totalDuration)
      .set("#audit", {css:{zIndex: 100}}, "-="+0.5/$totalDuration)
      .addLabel("audit")
      //Audit end

      //roadmap begin 2 13
      // .to("#stats", {duration: 0.5/$totalDuration})
      
      .to("#audit", {
        autoAlpha: 0,
        duration: 1/$totalDuration
      })
      .to("#ecosystem", {
        autoAlpha: 0,
        duration: 1/$totalDuration
      },"-="+1/$totalDuration)
      .to("#roadmap", {
        autoAlpha: 1,
        duration: 1/$totalDuration
      },"-="+1/$totalDuration)
      .set("#roadmap", {css:{zIndex: 100}}, "-="+0.5/$totalDuration)
      .set("#audit", {css:{zIndex: 0}}, "-="+0.5/$totalDuration)
      
      // .to("#stats", {duration: 0.5/$totalDuration})
      .addLabel("roadmap")
      //roadmap end

      //join begin 2 15
      // .to("#stats", {duration: 0.5/$totalDuration})
      .to(".roadmap-balls", {
        top: toPX("-100vh"),
        duration: 1/$totalDuration
      })
      .to(".join-ball", {
        y: 0,
        autoAlpha: 1,
        duration: 1/$totalDuration
      },"-="+1/$totalDuration)
      .to(".join-content", {
        y: 0,
        autoAlpha: 1,
        duration: 1/$totalDuration
      },"-="+1/$totalDuration)
      .to(".contact-us", {
        autoAlpha: 1,
        duration: 1/$totalDuration
      },"-="+1/$totalDuration)
      .to("#roadmap", {
        autoAlpha: 0,
        duration: 1/$totalDuration
      },"-="+1/$totalDuration)
      .set("#stats", {css:{zIndex: 0}})
      .set(".join-page", {css:{zIndex: 100}})
      // .to("#stats", {duration: 0.5/$totalDuration})
      .addLabel("join")
      //join end

      // .to("#countdown-page", {
      //   duration: 3/$totalDuration
      // })
      // .addLabel("end")


      // .to("#countdown-page", {
      //   autoAlpha: 0,
      // })
      // .set("#countdown-page", {css:{zIndex: 0}})
      // .to("#stats", {
      //   autoAlpha: 1,
      // })
      // .set("#stats", {css:{zIndex: 10}})
      // .addLabel("feature")
      // .to("#stats", {
      //   autoAlpha: 0,
      // },"+=0.25")
      // .to("#governance", {
      //   autoAlpha: 1,
      // },"-=0.25")
      // .addLabel("end")
    setTl(tl);
    return () => {
      tl.kill();
      setTl(null);
    }
  }, [isTabletOrMobile]);

  return (
    <>
      <TopMenu tl={tl} s={s}/>
      <div className="App " id="home">
        {(!isTabletOrMobile) && (
          <Bg />
        )}
        
        <CountDown />
        <Feature />
        <Governance />
        <Ecosystem />
        <Investors />
        <Audit />
        {isTabletOrMobile?(
          <RoadmapMb />
        ):(
          <Roadmap />
        )}
        <Join />      
        
      </div>
      {(!isTabletOrMobile) && (
        <div style={{position: "absolute", left: 0, top: 0,right: 0, zIndex: -1}}>
          <div className='placeholder_box'></div>
          <div className='placeholder_box'></div>
          <div className='placeholder_box'></div>
          <div className='placeholder_box'></div>
          <div className='placeholder_box'></div>
          <div className='placeholder_box'></div>
          <div className='placeholder_box'></div>
          <div className='placeholder_box'></div>
          <div className='placeholder_box'></div>
          <div className='placeholder_box'></div>
          {/* 这里要多一个 */}
        </div>
      )}
    
    </>
  );
}

export default App;

import "./Bg.scss";
import React, { useEffect } from 'react';
import { Ball } from './BgElements';
import gsap from 'gsap';
// import ScrollToPlugin from "gsap/ScrollToPlugin";
import ScrollTrigger from "gsap/ScrollTrigger";
// import { toPX } from "./Utils";

export default function Bg() {
  gsap.registerPlugin(ScrollTrigger);

  useEffect(() =>{
    
  }, []);

  return (
    <div className="bg-wrapper">
      <div className='stage1'>
        {/* <Ball size="217" x="-74" y="119" />
        <Ball size="54" x="382" y="75" />
        <Ball size="33" x="1407" y="86" />
        <Ball size="62" x="988" y="196" />
        <Ball size="128" x="189" y="626" /> */}
      </div>
      <div className='stage2'>
        <div className="stage2_bg"></div>
      </div>
      <div className='stage3'>
        {/* <div className="stage2_bg"></div> */}
        <div className="eco-balls">
          {/* <Ball size="33" x="203" y="202" />
          <Ball size="33" x="1222" y="193" />
          <Ball size="33" x="93" y="655" />
          <Ball size="33" x="1072" y="728" /> */}
        </div>
        
        <div className="eco-bg">
          <div className="eco-bg-control"></div>
        </div>

        <div className="roadmap-balls">
          <Ball size="128" x="341" y="-43" blur="true" />
          <Ball size="54" x="515" y="151" />
          <Ball size="128" x="61" y="236" />
          <Ball size="100" x="1018" y="186" />
          <Ball size="128" x="566" y="507" blur="true" /> {/** */}
          <Ball size="128" x="189" y="626" />
          <Ball size="380" x="1078" y="538" blur="true"/> {/** */}
        </div>
      </div>
      <div className="circle">
        <div className="gov-circle"></div>
      </div>
      <h2 className="gov-title">Governance</h2>

    </div>
  )
}
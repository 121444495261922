import "./Roadmap.scss";
import { ReactComponent as AddIcon } from "./../assets/icons/add.svg";
import React, { useState } from "react";

export default function Roadmap() {
  const [show, setShow] = useState(0);

  return (
    <section className="common-page roadmap-page" id="roadmap">
      <div className="content">
        <div className="left">
          <h2>
            2022
            <br />
            Roadmap
          </h2>
        </div>
        <div className="right">
          <div
            className={
              show === 0 ? "right-grid mode1" : "right-grid mode1 hide"
            }
          >
            <div className="item-wrapper1">
              <div className="right-grid-item" onClick={() => setShow(1)}>
                <div>
                  <span className="plus-btn">
                    <AddIcon />
                  </span>
                </div>
                <div className="textfont1">
                  <span className="color2">Q1</span> The Beginning
                </div>
                {/* <div><span className='bartag'>In progress</span></div> */}
              </div>
            </div>
            <div className="item-wrapper2">
              <div className="right-grid-item" onClick={() => setShow(2)}>
                <div>
                  <span className="plus-btn">
                    <AddIcon />
                  </span>
                </div>
                <div className="textfont1">
                  <span className="color2">Q2</span> More Stablecoins
                </div>
                {/* <div>
                  <span className="bartag">In progress</span>
                </div> */}
              </div>
            </div>
            <div className="item-wrapper3">
              <div className="right-grid-item" onClick={() => setShow(3)}>
                <div>
                  <span className="plus-btn">
                    <AddIcon />
                  </span>
                </div>
                <div className="textfont1">
                  <span className="color2">Q3</span> Pool Launch
                </div>
                <div><span className='bartag'>In progress</span></div>
              </div>
            </div>
            <div className="item-wrapper4">
              <div className="right-grid-item" onClick={() => setShow(4)}>
                <div>
                  <span className="plus-btn">
                    <AddIcon />
                  </span>
                </div>
                <div className="textfont1">
                  <span className="color2">Q4</span> Crosschain
                </div>
                {/* <div><span className='bartag'>In progress</span></div> */}
              </div>
            </div>
          </div>

          <div className={show === 0 ? "mode2 hide" : "mode2"}>
            {/* <img src={RoadmapImg} alt="roadmap" /> */}
            <div className="panel roadmap-panel">
              <div className="panel-close" onClick={() => setShow(0)}></div>
              <div className="left">
                <span
                  className={show === 1 ? "btn active" : "btn"}
                  onClick={() => setShow(1)}
                >
                  Q1
                </span>
                <span className="line"></span>
                <span
                  className={show === 2 ? "btn active" : "btn"}
                  onClick={() => setShow(2)}
                >
                  Q2
                </span>
                <span className="line"></span>
                <span
                  className={show === 3 ? "btn active" : "btn"}
                  onClick={() => setShow(3)}
                >
                  Q3
                </span>
                <span className="line"></span>
                <span
                  className={show === 4 ? "btn active" : "btn"}
                  onClick={() => setShow(4)}
                >
                  Q4
                </span>
              </div>

              <div className={show === 1 ? "right" : "right hide"}>
                <div className="textfont1">
                  <span className="color2">Q1</span> The Beginning
                </div>
                <div>
                  <ul>
                    <li>Add basepool for USDT, USDC, DAI, BUSD</li>
                    <li>Technical Research & Validation</li>
                    <li>Brand design & VI</li>
                    <li>Litepaper/Pitch deck</li>
                    <li>Astar incubation project application</li>
                    <li>Stablecoin swap desktop UI launch</li>
                    <li>Seed round fundraise</li>
                  </ul>
                </div>
              </div>

              <div className={show === 2 ? "right" : "right hide"}>
                <div className="textfont1">
                  <span className="color2">Q2</span> More Stablecoins{" "}
                  <span className="bartag">In progress</span>
                </div>
                <div>
                  <ul>
                    <li>Official Website launch</li>
                    <li>DApp production launch</li>
                    <li>$SRS token launch (Non-transferrable)</li>
                    <li>
                      Add governance using snapshot for SIPs (Sirius Improvement
                      Proposals)
                    </li>
                    <li>Ve locking and voting functions go live.</li>
                    <li>DApp mobile version launch</li>
                    <li>
                      Build the Sirius ecosystem with partners on the Astar
                      chain
                    </li>
                    <li>Kickoff rewarding program</li>
                    <li>Early Adopter rewarding program</li>
                    <li>Dashboard launch (one place to see all rewards)</li>
                    <li>Documentation publish</li>
                    <li>Stable Metapools launch. (BAI,oUSD, Starlay, etc).</li>
                    <li>Crypto Metapools launch. (WBTC, WETH, WBNB, etc).</li>
                    <li>Fiat-pegged crypto metapools launch (JPYC).</li>
                    <li>DApp supports i18n. (Multilingual).</li>
                    <li>Vesting/Claim pages launch.</li>
                  </ul>
                </div>
              </div>

              <div className={show === 3 ? "right" : "right hide"}>
                <div className="textfont1">
                  <span className="color2">Q3</span> Sirius Ecosystem
                </div>
                <div>
                  <ul>
                    <li>xcDOT/ASTR/4SRS crypto basepool Tripool launch.</li>
                    <li>
                      lDOT/tDOT/DOT, nASTR/vASTR/ASTR stable pairs launch.
                    </li>
                    <li>
                      Polkadot native Stablecoins basepool launch.
                      (aUSD/BAI/4SRS).
                    </li>
                    <li>More XCM assets metapools launch. (xcGLMR, etc.)</li>
                    <li>Admin fee collection for veHolders launch.</li>
                    <li>Multisig for smart contracts</li>
                    <li>Private fundraise</li>
                    <li>Protocols optimization</li>
                    <li>
                      Factory (for creating tripool based metapool)
                      research/development.
                    </li>
                    <li>Website V2 launch</li>
                    <li>
                      DApp V2 launch to provide a smoother user experience
                    </li>
                    <li>DAO launch</li>
                    <li>Permissionless pool creation</li>
                    <li>Sirius API & SDK publish</li>
                    <li>Unlock Transfer of $SRS</li>
                    <li>Public fundraise (IDO).</li>
                    <li>Potential CEXes listing</li>
                  </ul>
                </div>
              </div>

              <div className={show === 4 ? "right" : "right hide"}>
                <div className="textfont1">
                  <span className="color2">Q4</span> Multichain and DAO
                </div>
                <div>
                  <ul>
                    <li>Multichain/crosschain investigation/deployment.</li>
                    <li>Native bridge on polkadot parachain research.</li>
                    <li>
                      Make SRS as a XCM based assets crossing parachains on
                      Polkadot.
                    </li>
                    <li>Smoothly move governance from committee to DAO</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

import "./Audit.scss"
import AUDIT_LEFT1 from "./../assets/audit_left1.png"
import AUDIT_LEFT2 from "./../assets/audit_left2.png"
import AUDIT_RIGHT from "./../assets/audit_right.png"
import { ReactComponent as Arrow } from "./../assets/icons/arrow.svg"

export default function Audit() {

  return (
    <section className="common-page audit-page" id="audit">
      <div className='content'>
        <h4>Audit</h4>
        <div className="audit-content">
          <div className="audit-content-left">
            <h2 className="color1">SECURE PROTOCOL</h2>
            <div>
              <img src={AUDIT_LEFT2} alt=""/>
              <img src={AUDIT_LEFT1} alt=""/>
            </div>
            <div>
              <a className="button secondary" href="https://github.com/SiriusFinance/siriusfinance-contract/blob/main/audit/" target="_blank" rel="noreferrer">Audit report detail<Arrow /></a>
            </div>
          </div>
          <div className="audit-content-right">
            <img src={AUDIT_RIGHT} alt="" />
          </div>
        </div>
        
      </div>
    </section>
  )
}
import './TopMenu.scss';
import Logo from './../assets/Logo.png';
import { ReactComponent as Arrow } from './../assets/icons/arrow.svg';
import { ReactComponent as MenuIcon } from './../assets/mobile/menu.svg';
import CloseIcon from './../assets/icons/close.png';
import gsap from 'gsap';
import ScrollToPlugin from "gsap/ScrollToPlugin";
import { useMediaQuery } from 'react-responsive'
import { ReactComponent as Discord } from './../assets/icons/Discord.svg'
import { ReactComponent as Email } from './../assets/icons/Email.svg'
import { ReactComponent as Github } from './../assets/icons/Github.svg'
import { ReactComponent as Medium } from './../assets/icons/Medium.svg'
import { ReactComponent as Telegram } from './../assets/icons/Telegram.svg'
import { ReactComponent as Twitter } from './../assets/icons/Twitter.svg'
import React, { useState } from 'react';

export default function TopMenu({tl, s}) {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' })
  let scrollforward = null;

  if(!isTabletOrMobile) {
    gsap.registerPlugin(ScrollToPlugin);
    scrollforward = (_id) => {
      const percent = tl.labels[_id];
      // console.log(tl['scrollTrigger']['start']);
      // console.log(tl['scrollTrigger']['end']);
      // console.log(percent);
      // console.log(tl['scrollTrigger']['end']*percent);
      gsap.to(window, {duration: 0.5, scrollTo: tl['scrollTrigger']['end']*(percent)});
    }
  }else{
    scrollforward = () => {
      s.slideTo(0);
      setMenuShow(0);
    }
  }

  const [menuShow, setMenuShow] = useState(0);
  const swiperTo = (_i) => {
    s.slideTo(_i);
    setMenuShow(0);
  }
  return (
    <>
      <header className='header'>
        <div className='header-inner'>
          <div className='logo'><span className='atag' onClick={() => scrollforward("home")}><img src={Logo} alt="logo"/></span></div>
          {!isTabletOrMobile && (
            <div className='nav'>
              {/* <span className='atag' onClick={() => scrollforward("feature")}>Stats</span> */}
              <span className='atag' onClick={() => scrollforward("governance")}>Governance</span>
              <span className='atag' onClick={() => scrollforward("ecosystem")}>Ecosystem</span>
              <span className='atag' onClick={() => scrollforward("roadmap")}>Roadmap</span>
              <a target="_blank" rel="noreferrer" href="https://github.com/SiriusFinance/gitbook/blob/main/Litepaper.pdf" className='atag'>Litepaper</a>
              <a target="_blank" rel="noreferrer" href="https://docs.sirius.finance/" className='atag'>Docs<Arrow /></a>
              <a target="_blank" rel="noreferrer" href='https://app.sirius.finance/' className='button secondary'>
                Launch App 
              </a>
              {/* <button className='secondary'>
                Launch App
                <span className="bartag">Coming Soon</span>
              </button> */}
            </div>
          )}

        </div>
      </header>
      {isTabletOrMobile && (
        <>
          <div className='menu-icon' onClick={() => setMenuShow(1)} >
            <MenuIcon />
          </div>
          <div className={menuShow?"mobile-menu":"mobile-menu hide"}>
            <span className='menu-icon' onClick={() => setMenuShow(0)}>
              <img src={CloseIcon} alt='closeIcon' width="100%"/>
            </span>
            <div className='menu-panel'>
              {/* <span className='menu-link' onClick={() => swiperTo(1)}>Stats</span> */}
              <span className='menu-link' onClick={() => swiperTo(2)}>Governance</span>
              <span className='menu-link' onClick={() => swiperTo(5)}>Ecosystem</span>
              <span className='menu-link' onClick={() => swiperTo(6)}>Roadmap</span>
              <a target="_blank" rel="noreferrer" href="https://github.com/SiriusFinance/gitbook/blob/main/Litepaper.pdf" className='menu-link'>Litepaper</a>
              <a href="https://docs.sirius.finance" target="_blank" rel="noreferrer" className='menu-link'>Docs<Arrow /></a>
              
              <a target="_blank" rel="noreferrer" href='https://app.sirius.finance/' className='button secondary'>
                Launch App 
              </a>
            </div>
            <div className='menu-contactus'>
              <div className='textfont2'>Contact us</div>
              <div>
                <a target="_blank" rel="noreferrer" href='mailto:business@sirius.finance'><Email /></a>
                <a target="_blank" rel="noreferrer" href='https://twitter.com/Sirius_Finance'><Twitter /></a>
                <a target="_blank" rel="noreferrer" href='https://github.com/SiriusFinance'><Github /></a>
                <a target="_blank" rel="noreferrer" href='https://t.me/siriusfinanceofficial'><Telegram /></a>
                <a target="_blank" rel="noreferrer" href='https://medium.com/@siriusfinance'><Medium /></a>
                <a target="_blank" rel="noreferrer" href='https://discord.gg/7yHgwnWF7F'><Discord /></a>
              </div>
            </div>
           
          </div>
        </>
      )}
    </>
  )
}
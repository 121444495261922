import "./Join.scss"
import React from 'react'
import Discord from './../assets/icons/Discord.svg'
import Email from './../assets/icons/Email.svg'
import Github from './../assets/icons/Github.svg'
import Medium from './../assets/icons/Medium.svg'
import Telegram from './../assets/icons/Telegram.svg'
import Twitter from './../assets/icons/Twitter.svg'

export default function Join() {

  return (
    <section className="common-page join-page" id="docs">
      <div className="join-content">
        <h1>Join our community</h1>
        <div>
          <a href="https://app.sirius.finance/" target="_blank" rel="noreferrer" className='button primary'>Launch App
          
          </a>
          {/* <button className='primary'>Launch App
          <span className="bartag">Coming Soon</span>
          </button> */}
          <a href="https://discord.gg/7yHgwnWF7F" target="_blank" rel="noreferrer" className='button secondary'>Join Discord</a>
        </div>
      </div>
      <div className='join-ball'>

      </div>
      <div className='contact-us'>
        <div className='textfont2'>Contact us</div>
        <div>
          <a target="_blank" rel="noreferrer" href='mailto:business@sirius.finance'><img src={Email} alt="Email"/></a>
          <a target="_blank" rel="noreferrer"  href='https://twitter.com/Sirius_Finance'><img src={Twitter} alt="Twitter" /></a>
          <a target="_blank" rel="noreferrer"  href='https://github.com/SiriusFinance'><img src={Github} alt="Github" /></a>
          <a target="_blank" rel="noreferrer"  href='https://t.me/siriusfinanceofficial'><img src={Telegram} alt="Telegram" /></a>
          <a target="_blank" rel="noreferrer"  href='https://medium.com/@siriusfinance'><img src={Medium} alt="Medium" /></a>
          <a target="_blank" rel="noreferrer"  href='https://discord.gg/7yHgwnWF7F'><img src={Discord} alt="Discord"/></a>
        </div>
      </div> 
    </section>
  )
}
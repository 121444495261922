import "./Ecosystem.scss"
import React from 'react'

export default function Ecosystem() {

  return (
    <section className="common-page ecosystem-page" id="ecosystem">
      <div className='content'>
        <h2>Ecosystem</h2>
      </div>
      {/* <img src={EcoImg} alt="ecoimg"/> */}
    </section>
  )
}
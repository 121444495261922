import "./CountDown.scss";
// import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive'
import Arrow from "./../assets/mobile/arrow.svg";
import { useEffect, useState, useCallback } from "react";
import { commify, TryRedo } from "./Utils";

export default function CountDown() {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  /** tvl begin*/
  
  // const swapStatsURI = "https://graph.sirius.finance/static/volume.json"
  // const fetchSwapStatsNow = () =>
  // fetch(`${swapStatsURI}`, { cache: "no-cache" })
  //   .then((res) => {
  //     if (res.status >= 200 && res.status < 300) {
  //       return res.json()
  //     }
  //     throw new Error("Unable to fetch swap stats from IPFS")
  //   })
  //   .then((body) => {
  //     return body
  //   })
  const [TotalTVL, setTotalTVL] = useState(0);
  // const getTVL = useCallback(() => {
  //   const contract_addresses = [
  //     "0x417e9d065ee22dfb7cc6c63c403600e27627f333", //4pool
  //     "0xd18abe9bcedeb5a9a65439e604b0be8db0bdb176",
  //     "0x290c7577d209c2d8db06f377af31318ce31938fb",
  //     "0x0fB8C4eB33A30eBb01588e3110968430E3E69D58",
  //     "0x3cd1fa4eeefdf6c30e66c66a474e8e4dd509f54c",//jpyc
  //   ]
  //   TryRedo(() => {
  //     fetchSwapStatsNow().then((data) => {
  //       let TTVL = 0;
  //       Object.keys(data).forEach((key) => {
  //         const d = data[key];
  //         contract_addresses.forEach((v, k) => {
  //           if(key.toUpperCase() === v.toUpperCase()){
  //             console.log(`${key}:${d['TVL']}`);
  //             TTVL += +d['TVL'];
  //           }
  //         })
  //       })
  //       setTotalTVL(TTVL);
  //     })
  //   });
  // }, []);

  
  // useEffect(() => {
  //   getTVL()
  //   const t = setInterval(() => {
  //     getTVL();
  //   }, 60*1000);
  //   return () => clearInterval(t);
  // }, [getTVL]);
  /** tvl end*/
  
  /** defillama tvl begin */
  const llamaUrl = "https://api.llama.fi/protocol/sirius-finance";
  const getLlamaData = useCallback(() => {
    TryRedo(() => {
      fetch(`${llamaUrl}`, { cache: "no-cache" })
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          return res.json()
        }
        throw new Error("Unable to fetch swap stats from IPFS")
      })
      .then((body) => {
        // console.log(body);
        const TTVL = Math.round(body.currentChainTvls.Astar + body.currentChainTvls.staking);
        setTotalTVL(TTVL);
        return body
      });
    });
  }, [llamaUrl]);

  useEffect(()=>{
    getLlamaData();
    const t = setInterval(() => {
      getLlamaData();
    }, 60*1000);
    return () => clearInterval(t);
  }, [getLlamaData]);
  /** defillama tvl end */

  //倒计时 begin
  // const future_time = new Date('2022/4/16 14:00 UTC').valueOf();
  // const now_time = new Date().valueOf();
  // const t_fn = (maxtime) => {
  //   const days = Math.floor(maxtime / 60 / 60 / 24);
  //   const hours = Math.floor(maxtime / 60 / 60 % 24);
  //   const minutes = Math.floor(maxtime / 60 % 60);
  //   const seconds = Math.floor(maxtime % 60);
  //   const add_0 = (num) => {
  //     if(num < 10){
  //       return '0'+num;
  //     }
  //     return num;
  //   }
  //   return {days: add_0(days),hours: add_0(hours), minutes: add_0(minutes), seconds: add_0(seconds)};
  // }

  // const [maxtime, setMaxtime] = useState((future_time - now_time)/1000);
  
  // useEffect(() => {
  //   const t = setInterval(() => {
  //     setMaxtime((c) => c-1);
  //   }, 1000);
  //   return () => clearInterval(t);
  // }, []);
  //倒计时 end

  return (
    // <section className="common-page countdown-page" id="countdown-page">
    //   <div className="time-wrapper">
    //     <div className="time">
    //       <div className="time-top color2">
    //         <span>{t_fn(maxtime).days}<span>{isTabletOrMobile?"days":"DAYS"}</span></span>
    //         <span>{t_fn(maxtime).hours}<span>{isTabletOrMobile?"hours":"HOURS"}</span></span>
    //         <span>{t_fn(maxtime).minutes}<span>{isTabletOrMobile?"mins":"MINUTES"}</span></span>
    //         <span>{t_fn(maxtime).seconds}<span>{isTabletOrMobile?"secs":"SECONDS"}</span></span>
    //       </div>
    //     </div>
    //     <div><a target="_blank" rel="noreferrer" href='https://forms.gle/hCR9KG6wp9Wo8wjU6' className="button secondary">Investment & Business</a></div>
    //   </div>

    //   <div className='content-text'>
    //     <h3>Sirius Finance official product is launching <br />on <span className='color1'>April 16th</span></h3>
        
    //   </div>
    //   <div className='bottom'>first stablecoin AMM infrastructure on Astar Network</div>
      
    <section className="common-page countdown-page style-online" id="countdown-page">
      <div className='content-text'>
        <h4>Sirius Finance currently has locked</h4>
        <h1><span className="color2">${commify(TotalTVL.toString())}</span>&nbsp;of assets</h1>
        <div><a target="_blank" rel="noreferrer" href='https://forms.gle/hCR9KG6wp9Wo8wjU6' className="button secondary">Investment & Business</a></div>
      </div>
      <div className='bottom'>first stablecoin AMM infrastructure on Astar Network</div> 
      


      {/* 总是会有 below */}
      {isTabletOrMobile && (
        <div className="arrow"><img src={Arrow} alt="arrow"/></div>
      )} 

    </section>
  )
}

import './Investors.scss'

const brands = [
  { img: 'astar.svg' },
  { img: 'acala.svg' },
  { img: 'next_web_capital.svg' },
  { img: 'DFG.svg' },
  { img: 'AU21.svg' },
  { img: 'rok_capital.svg' },
  { img: 'cogitent.svg' },
  { img: 'GTS.svg' },
  { img: 'za_ventures.svg' },
  { img: 'LOCK_ventures.svg' },
  { img: 'celer.svg' },
  { img: 'SlowMist.png' },
  { img: 'PeckShield.svg' },
  { img: 'Avault.svg' },
  { img: 'AstridDAO.svg' },
  { img: 'astar_degens.svg' },
  { img: 'crypto_times.svg' },
  { img: 'ORCUS.svg' },
  { img: 'Alnair.svg' },
  { img: 'libra.svg' },
  { img: 'starbank.svg' },
  { img: 'algem.svg' },
  { img: 'subsquid.svg', url: 'https://subsquid.io' },
  { img: 'talisman.svg', url: 'https://talisman.xyz' },
  { img: 'subwallet.png', url: 'https://subwallet.app' },
  { img: 'PolkaEx.svg', url: 'https://app.polkaex.io' }
]

export default function Ecosystem() {
  return (
    <section className="common-page investers-page" id="investors">
      <div className="content">
        <h4>Investors & Partners</h4>
        <div className="brands">
          {brands.map((i, index) => (
            <img key={index} className="img" src={`/img/investors/${i.img}`} alt={i.img} />
          ))}
        </div>
      </div>
    </section>
  )
}
